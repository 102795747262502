@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&display=swap');
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		'Source Sans Pro', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.table-options {
	left: 0 !important;
	position: sticky !important;
	z-index: 999 !important;
	background-color: white;
	box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.15);
	min-width: 3rem !important;
	width: 3rem !important;
	padding: 0px !important;
}
.table-options-header {
	left: 0 !important;
	position: sticky !important;
	z-index: 999 !important;
	background-color: white;
	min-width: 3rem !important;
	width: 3rem !important;
	padding: 0px !important;
}

.table-options-header
	> .MuiDataGrid-columnHeaderDraggableContainer
	> .MuiDataGrid-columnHeaderTitleContainer {
	display: flex;
	justify-content: center;
	color: #1976d2;
	letter-spacing: 0.02857em;
	font-size: 0.8125rem;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
	font-weight: 600 !important;
}

.cell-wrap {
	white-space: normal !important;
	word-wrap: break-word !important;
	word-break: break-word !important;
	overflow: visible !important;
}
.table-options > button {
	margin-left: auto !important;
	margin-right: auto !important;
}

#root {
	overflow: auto;
}
